<template>
  <v-dialog v-model="dialog" class="w-75">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" tile
        ><v-icon>mdi-plus</v-icon>
        Select Learner
      </v-btn>
    </template>
    <v-card :loading="loading">
      <v-card-title
        >Select Learner
        <v-spacer></v-spacer>
        <v-text-field v-model="search" label="Search" placeholder="Search here">
        </v-text-field>
      </v-card-title>

      <v-card-text style="height: auto">
        <v-row>
          <v-col
            ><v-data-table
              :headers="headers"
              :items="learners"
              :items-per-page="10"
              class="elevation-0"
              single-select
              :search="search"
              dense
              @click:row="selRow"
            >
            <template v-slot:item.surname="{ item }">
              <v-icon small color="orange"  v-if="item.disabled">
              mdi-hand-back-right
            </v-icon>
            {{ item.surname }}
            
          </template>
            </v-data-table
          ></v-col>
          <v-col>
            <v-form :loading="loading">
              <v-row>
                <v-col class="text-h6"> Required Fields (*) </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="* Grade Level"
                    v-model="tmp.gradeLvl"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="typelist"
                    label="* Type"
                    clearable
                    placeholder="Select Type"
                    item-text="name"
                    item-value="id"
                    v-model="tmp.type"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    label="* Value "
                    v-model="tmp._value"
                    @blur="getAssessToollevel"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Level"
                    v-model="tmp._level"
                    readonly
                    :loading="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
        
            <v-sheet elevation="10"  color="grey lighten-4" class="pa-5" rounded="rounded">
              <p>
                Selected:
                <span class="text-body-1 font-weight-bold"
                  >{{ tmp.learner.surname }}, {{ tmp.learner.gname }}</span
                >
              </p>
              <p>Grade Level: <span class="text-body-1 font-weight-bold ">{{ tmp.gradeLvl }}</span></p>
              <p>Type:<span class="text-body-2 font-weight-bold "> {{ tmp.type }}</span></p>
              <p>Value:<span class="text-body-2 font-weight-bold "> {{ tmp._value }}</span></p>
              <p>Level: <span class="text-body-2 font-weight-bold ">{{ tmp._level }}</span></p>
              
            </v-sheet></v-col
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="dialog = false"> Close </v-btn>
        <v-spacer></v-spacer>
       
        <v-btn color="blue darken-1" text @click="AddLearner" size="large">
          Add Learner
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { get, sync, dispatch } from "vuex-pathify";

export default {
  props: {
    assesstool: String,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      headers: [
        { text: "Surname", value: "surname" },
        { text: "Given Name", value: "gname" },
        { text: "Middle Name", value: "mname" },
     
      ],
      search: "",
      tmp: {
        id: "",
        learner: {
          surname: "",
          gname: "",
        },
        gradelvl: "",
        type: "",
        _value: "",
        _level: "",
      },
      typelist: [{ name: "MATH" }, { name: "ENG" }, { name: "FIL" },{name:"HIL"}],
    };
  },

  computed: {
    ...get("learners", ["learners"]),
    ...sync("learners", ["selLearner", "learners"]),
    ...sync("assessment", [
      "pretestAssessment",
      "pretestLearners",
      "assesstoollevel",
      "newassessment",
    ]),
  },

  beforeMount() {
    dispatch("learners/load");
  },
  methods: {
    selRow(item) {
      //add Learner to Assessment Pretest list
      this.tmp.learners_id = item.id;
      this.tmp.learner.surname = item.surname;
      this.tmp.learner.gname = item.gname;
    },

    AddLearner() {
      this.pretestLearners.push(this.tmp);
      this.clearTmp();
      this.$toast.success("Learner Added");
      this.dialog = false;
    },

    async getAssessToollevel() {
      this.loading = true;

      let payload = {
        assesstool: this.newassessment
          ? this.pretestAssessment.assessment_tool.title
          : this.pretestAssessment.assessment_tool,
        _type: this.tmp.type,
        value: this.tmp._value,
      };

      dispatch("assessment/getAssessmentLevel", payload);
      this.tmp._level = this.assesstoollevel;
      this.loading = false;
    },

    clearTmp() {
      this.tmp = {
        id: "",
        learner: {
          surname: "",
          gname: "",
        },
        gradelvl: "",
        type: "",
        _value: "",
        _level: "",
      };
    },
  },
};
</script>
